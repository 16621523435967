/*
 * NotFound.js
 */
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import s from './NotFound.css';

const baseContainerStyle = {
  "paddingTop": "20px",
  "paddingBottom": "40px",
  // "fontFamily": "museo-sans-rounded,sans-serif",
  "textAlign": "-webkit-center",
  "backgroundColor": "#efefef",
 };

const containerStyle = {
  "padding": "30px",
  "borderRadius": "20px",
  "backgroundColor": "white",
  "margin": "35px 35px",
  "maxWidth": "800px",
  "textAlign": "left",
};

export default function NotFound({ title }) {
  useStyles(s);

  return (
    <div style={baseContainerStyle}>
      <div style={containerStyle}>
        <h1>{title}</h1>
        <p>Sorry, the page you were trying to view does not exist.</p>
      </div>
    </div>
  );
}

NotFound.propTypes = {
  title: PropTypes.string.isRequired,
};
